export enum Key {
  Undefined = "Undefined",
  Enter = "Enter",
  Ctrl = "Ctrl",
  Escape = "Escape",
  Space = "Space",
  DownArrow = "DownArrow",
  UpArrow = "UpArrow",
  RightArrow = "RightArrow",
  LeftArrow = "LeftArrow",
}

export class KeyEvent {
  public key?: string;
  public code?: string;
  public which?: number;

  public static ToEnum(event: KeyEvent): Key {
    if (event.which === 13) {
      return Key.Enter;
    }

    // CTRL
    if (event.which === 17) {
      return Key.Ctrl;
    }

    // CMD left
    if (event.which === 91) {
      return Key.Ctrl;
    }

    // CMD right
    if (event.which === 93) {
      return Key.Ctrl;
    }

    if (event.which === 27) {
      return Key.Escape;
    }

    if (event.which === 32) {
      return Key.Space;
    }

    if (event.which === 38) {
      return Key.UpArrow;
    }

    if (event.which === 40) {
      return Key.DownArrow;
    }

    if (event.which === 39) {
      return Key.RightArrow;
    }

    if (event.which === 37) {
      return Key.LeftArrow;
    }

    return Key.Undefined;
  }
}

import * as React from "react";
import { Form } from "react-bootstrap";
import AppProperties from "../library/appLogic/AppProperties";

export default function WritingDisplay(props: AppProperties) {
  const handleChange = (e: any) => {
    e.preventDefault();
    props.setReadingText(e.target.value);
  };
  return (
    <Form style={{ height: "100%", width: "100%" }}>
      <Form.Control
        style={{
          height: "100%",
          width: "100%",
          background: "#000",
          color: "#afafaf",
        }}
        placeholder="Text to speed read. Press Ctrl+Enter when you're ready to read."
        as="textarea"
        // focus on this element when it loads
        autoFocus
        value={props.readingText}
        onChange={handleChange}
        // select all on focus
        onFocus={(event) => event.target.select()}
      />
    </Form>
  );
}

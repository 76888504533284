import {
  NonReferenceTypeInputOutputProvider,
  ReferenceTypeInputOutputProvider,
} from "./InputOutputProvider";
import { StringExtensions } from "../StringExtensions";

export default abstract class TextInputOutputProvider
  implements
    ReferenceTypeInputOutputProvider,
    NonReferenceTypeInputOutputProvider
{
  protected abstract SetRaw(key: string, val: string): void;
  protected abstract GetRaw<T>(key: string): T | null;

  public SetReferenceType<T>(key: string, value: T): void {
    this.SetRaw(key, JSON.stringify(value));
  }

  public SetNonReferenceType<T>(key: string, value: T): void {
    this.SetRaw(key, `${value}`);
  }

  public GetReferenceType<T extends {}>(
    type: { new (): T },
    key: string
  ): T | null {
    try {
      return StringExtensions.DeserializeJson(type, this.GetRaw(key));
    } catch (e) {
      return null;
    }
  }

  public GetNonReferenceType<T>(key: string): T {
    let value = this.GetRaw(key) as T;
    if (!value) {
      throw new Error(`No value found for key '${key}'`);
    }

    return value;
  }
}

import { AppConfig } from "../library/appLogic/AppConfig";
import { Button } from "react-bootstrap";
import { KeyEvent, Key } from "../templateCommon/ts/KeyboardControls";
import { PlaybackMode } from "../library/playback/PlaybackMode";
import * as Events from "../templateCommon/react/Events";
import * as React from "react";
import AppProperties from "../library/appLogic/AppProperties";
import Playback from "../library/playback/Playback";
import { PlaybackSessionState } from "../library/playback/PlaybackSessionState";
import SessionStorageIO from "../templateCommon/ts/io/SessionStorageIO";

export default function ReadingDisplay(props: AppProperties) {
  const progressBarHeight = 10;
  const [sessionState, setSessionState] = React.useState<PlaybackSessionState>(
    PlaybackSessionState.LoadOrDefault(props.readingText)
  );
  const words = props.readingText.trim().split(/\s+/);

  function play() {
    Playback.Play();
    setSessionState(sessionState.Clone());
  }

  function pause() {
    Playback.Pause();
    setSessionState(sessionState.Clone());
  }

  function incrementWord() {
    sessionState.IncrementWord(words);
    if (sessionState.IsDone(words)) {
      Playback.Pause();
    }
    setSessionState(sessionState.Clone());
    PlaybackSessionState.SetCookie(sessionState);
  }

  function decrementWord() {
    sessionState.DecrementWord(words);
    setSessionState(sessionState.Clone());
    PlaybackSessionState.SetCookie(sessionState);
  }

  function getWordForDisplay(): JSX.Element {
    switch (Playback.GetValue()) {
      case PlaybackMode.Paused:
        return <div>{sessionState.GetCurWord(words)}</div>;
      case PlaybackMode.Playing:
        if (!sessionState.IsDone(words)) {
          if (sessionState.IsCurrentWordDone()) {
            incrementWord();
          } else {
            setTimeout(() => {
              sessionState.currentTimeInMs += 5;
              setSessionState(sessionState.Clone());
            }, 5);
          }
        }
        return <div>{sessionState.GetCurWord(words)}</div>;
      default:
        return <div>Error: Undefined playback mode</div>;
    }
  }

  Events.useEventListener("keydown", (event: KeyEvent) => {
    if (SessionStorageIO.Instance.GetAppMenuState()?.isOpen) {
      return;
    }
    switch (KeyEvent.ToEnum(event)) {
      case Key.Space:
        switch (Playback.GetValue()) {
          case PlaybackMode.Playing:
            pause();
            break;
          case PlaybackMode.Paused:
            play();
            break;
          default:
            break;
        }
        break;
      case Key.DownArrow:
        AppConfig.Update((config) => {
          let baseWordsPerMinute =
            config.maxWordsPerMinute - AppConfig.WORDS_PER_MINUTE_INCREMENT;
          if (baseWordsPerMinute <= 0) {
            return false;
          }
          config.maxWordsPerMinute = baseWordsPerMinute;

          return true;
        });
        break;
      case Key.UpArrow:
        AppConfig.Update((config) => {
          config.maxWordsPerMinute += AppConfig.WORDS_PER_MINUTE_INCREMENT;
          return true;
        });
        break;
      case Key.LeftArrow:
        if (Playback.GetValue() === PlaybackMode.Playing) {
          pause();
        }
        decrementWord();
        break;
      case Key.RightArrow:
        if (Playback.GetValue() === PlaybackMode.Playing) {
          pause();
        }
        incrementWord();
        break;
      default:
        break;
    }
  });

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        background: "#000",
        color: "#afafaf",
        fontWeight: "bold",
        fontSize: 40,
      }}
    >
      <div style={{ flexGrow: 1 }} />
      <div style={{ textAlign: "center" }}>{getWordForDisplay()}</div>
      <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <div style={{ flexGrow: 1 }} />
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }} />
          {Playback.GetValue() === PlaybackMode.Paused ? (
            <Button
              variant={`success`}
              onClick={() => decrementWord()}
              // todo: disabled={sessionState.currentIndex <= 0}
              // (currently disbaled button hovers above opened menu)
            >
              ⇦
            </Button>
          ) : null}
          <div style={{ flexGrow: 1 }} />
          {Playback.GetValue() === PlaybackMode.Paused ? (
            <Button variant={`success`} onClick={() => play()}>
              Read
            </Button>
          ) : (
            <Button variant={`success`} onClick={() => pause()}>
              Pause
            </Button>
          )}
          <div style={{ flexGrow: 1 }} />
          {Playback.GetValue() === PlaybackMode.Paused ? (
            <Button
              variant={`success`}
              onClick={() => incrementWord()}
              // todo: disabled={sessionState.IsDone(words)}
              // (currently disbaled button hovers above opened menu)
            >
              ⇨
            </Button>
          ) : null}
          <div style={{ flexGrow: 1 }} />
        </div>
        <div style={{ flexGrow: 1 }} />
      </div>
      <div style={{ height: progressBarHeight, background: "transparent" }}>
        <div
          style={{
            height: progressBarHeight,
            width: `${(sessionState.currentIndex / (words.length - 1)) * 100}%`,
            background: "#198754",
          }}
        />
      </div>
    </div>
  );
}

import {
  CustomValueType,
  MotionStyle,
  MotionValue,
  TargetAndTransition,
} from "framer-motion";
import SessionStorageIO from "./io/SessionStorageIO";

class SvgProps {
  public width: string = "";
  public height: string = "";
  public viewBox: string = "";
  public initial: string = "";
  public animate: string = "";
  public style: MotionStyle | undefined;
}

class SvgLineProps {
  public x1: string = "";
  public y1: string = "";
  public x2: string = "";
  public y2: string = "";
  public strokeWidth: number = 0;
  public stroke:
    | string
    | CustomValueType
    | MotionValue<number>
    | MotionValue<string>
    | MotionValue<any>
    | undefined;
  public custom: any | undefined;
  public strokeLinecap: "butt" | "round" | "square" | "inherit" | undefined;
}

export class AppMenuDisplayOptions {
  color?: string | undefined;
  background?: string | undefined;
  opacityOpen?: number | undefined;
  opacityClosed?: number | undefined;
  svgStrokeColor?: string | undefined;
}

export class AppMenuState {
  private static readonly MARGIN: number = 10;
  private static readonly OPACITY_OPEN: number = 1;
  private static readonly OPACITY_CLOSED: number = 0.5;
  private static readonly MENU_BUTTON_SIDE_LENGTH: number = 60;
  private static readonly TRANSITION_DURATION_S: number = 0.2;
  private static readonly SVG_STROKE_WIDTH: number = 5;
  private static readonly SVG_STROKE_COLOR: string = "#000";

  public isOpen: boolean = false;
  public isAnimationComplete: boolean = false;

  public IsMenuActive(): boolean {
    return this.isOpen || !this.isAnimationComplete;
  }

  public ToggleIsOpen(args: {
    handler: (isOpen: boolean) => void;
    setAppMenuState: (state: AppMenuState) => void;
  }): void {
    let newState = this.Clone();
    newState.isOpen = !this.isOpen;
    newState.isAnimationComplete = false;

    SessionStorageIO.Instance.SetAppMenuState(newState);
    args.setAppMenuState(newState);
    args.handler(newState.isOpen);
  }

  public GetAnimation(
    displayOptions?: AppMenuDisplayOptions
  ): TargetAndTransition {
    if (this.isOpen) {
      return {
        width:
          (window.visualViewport?.width ?? window.innerWidth) -
          AppMenuState.MARGIN * 2,
        height:
          (window.visualViewport?.height ?? window.innerHeight) -
          AppMenuState.MARGIN * 2,
        borderRadius: AppMenuState.MENU_BUTTON_SIDE_LENGTH / 2,
        margin: AppMenuState.MARGIN,
        opacity: displayOptions?.opacityOpen ?? AppMenuState.OPACITY_OPEN,
        transition: { duration: AppMenuState.TRANSITION_DURATION_S },
      };
    }
    return {
      width: AppMenuState.MENU_BUTTON_SIDE_LENGTH,
      height: AppMenuState.MENU_BUTTON_SIDE_LENGTH,
      borderRadius: AppMenuState.MENU_BUTTON_SIDE_LENGTH / 2,
      margin: AppMenuState.MARGIN,
      opacity: displayOptions?.opacityClosed ?? AppMenuState.OPACITY_CLOSED,
      transition: { duration: AppMenuState.TRANSITION_DURATION_S },
    };
  }

  public Clone(): AppMenuState {
    let clone = new AppMenuState();
    clone.isOpen = this.isOpen;
    clone.isAnimationComplete = this.isAnimationComplete;
    return clone;
  }

  public FinishAnimation(update: (newState: AppMenuState) => void): void {
    this.isAnimationComplete = true;
    update(this.Clone());
  }

  public GetMenuSvgProps(): SvgProps {
    let length = AppMenuState.MENU_BUTTON_SIDE_LENGTH;
    return {
      width: `${length}`,
      height: `${length}`,
      viewBox: `0 0 ${length} ${length}`,
      initial: "hidden",
      animate: "visible",
      style: {
        cursor: "pointer",
        borderRadius: AppMenuState.MENU_BUTTON_SIDE_LENGTH / 2,
      },
    };
  }

  public GetCloseMenuButtonSvgLinesProps(
    displayOptions?: AppMenuDisplayOptions
  ): Array<SvgLineProps> {
    let horizontalMargin = AppMenuState.MARGIN * 1.75;
    return [
      {
        strokeWidth: AppMenuState.SVG_STROKE_WIDTH,
        x1: `${horizontalMargin}`,
        y1: `${horizontalMargin}`,
        x2: `${AppMenuState.MENU_BUTTON_SIDE_LENGTH - horizontalMargin}`,
        y2: `${AppMenuState.MENU_BUTTON_SIDE_LENGTH - horizontalMargin}`,
        stroke: this.GetStrokeColor(displayOptions),
        custom: 0,
        strokeLinecap: "round",
      },
      {
        strokeWidth: AppMenuState.SVG_STROKE_WIDTH,
        x1: `${AppMenuState.MENU_BUTTON_SIDE_LENGTH - horizontalMargin}`,
        y1: `${horizontalMargin}`,
        x2: `${horizontalMargin}`,
        y2: `${AppMenuState.MENU_BUTTON_SIDE_LENGTH - horizontalMargin}`,
        stroke: this.GetStrokeColor(displayOptions),
        custom: 2,
        strokeLinecap: "round",
      },
    ];
  }

  public GetOpenMenuButtonSvgLinesProps(
    displayOptions?: AppMenuDisplayOptions
  ): Array<SvgLineProps> {
    let horizontalMargin = AppMenuState.MARGIN * 1.5;
    return [
      {
        strokeWidth: AppMenuState.SVG_STROKE_WIDTH,
        x1: `${horizontalMargin}`,
        y1: `${AppMenuState.MENU_BUTTON_SIDE_LENGTH / 3}`,
        x2: `${AppMenuState.MENU_BUTTON_SIDE_LENGTH - horizontalMargin}`,
        y2: `${AppMenuState.MENU_BUTTON_SIDE_LENGTH / 3}`,
        stroke: this.GetStrokeColor(displayOptions),
        custom: 0,
        strokeLinecap: "round",
      },
      {
        strokeWidth: AppMenuState.SVG_STROKE_WIDTH,
        x1: `${horizontalMargin}`,
        y1: `${AppMenuState.MENU_BUTTON_SIDE_LENGTH / 2}`,
        x2: `${AppMenuState.MENU_BUTTON_SIDE_LENGTH - horizontalMargin}`,
        y2: `${AppMenuState.MENU_BUTTON_SIDE_LENGTH / 2}`,
        stroke: this.GetStrokeColor(displayOptions),
        custom: 1,
        strokeLinecap: "round",
      },
      {
        strokeWidth: AppMenuState.SVG_STROKE_WIDTH,
        x1: `${horizontalMargin}`,
        y1: `${(2 * AppMenuState.MENU_BUTTON_SIDE_LENGTH) / 3}`,
        x2: `${AppMenuState.MENU_BUTTON_SIDE_LENGTH - horizontalMargin}`,
        y2: `${(2 * AppMenuState.MENU_BUTTON_SIDE_LENGTH) / 3}`,
        stroke: this.GetStrokeColor(displayOptions),
        custom: 2,
        strokeLinecap: "round",
      },
    ];
  }

  private GetStrokeColor(displayOptions?: AppMenuDisplayOptions): string {
    return displayOptions?.svgStrokeColor ?? AppMenuState.SVG_STROKE_COLOR;
  }
}

import CookieIO from "../../templateCommon/ts/io/CookieIO";

/** Properties that persist across app sessions */
export class AppConfig {
  public static readonly STORAGE_KEY = "AppConfig";
  public static readonly WORDS_PER_MINUTE_INCREMENT = 20;
  public maxWordsPerMinute: number = 300;
  public syllableDurationRatio: number = 0.3;

  public static Load(): AppConfig {
    let appConfig = CookieIO.Instance.GetReferenceType(
      AppConfig,
      AppConfig.STORAGE_KEY
    );

    if (appConfig) {
      return appConfig;
    }

    appConfig = new AppConfig();
    this.Save(appConfig);
    return appConfig;
  }

  static Save(config: AppConfig) {
    CookieIO.Instance.SetReferenceType(AppConfig.STORAGE_KEY, config);
  }

  public static Update(updateConfig: (config: AppConfig) => boolean): void {
    let config = AppConfig.Load();

    if (updateConfig(config)) {
      AppConfig.Save(config);
    }
  }
}

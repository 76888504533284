import * as syllable from "syllable";
import SessionStorageIO from "../../templateCommon/ts/io/SessionStorageIO";
import StringExtensions from "../StringExtensions";
import { AppConfig } from "../appLogic/AppConfig";
import { from } from "linq-to-typescript";

/** Playback data for a particular app session */
export class PlaybackSessionState {
  private static readonly STORAGE_KEY = "PlaybackSessionState";

  public currentIndex: number = 0;
  public currentTimeInMs: number = 0;
  public currentWordDurationInMs: number = 0;
  public textHash: string = "";

  constructor(textToRead?: string) {
    this.textHash = StringExtensions.ComputeHash(textToRead ?? "");
  }

  // #region Instance Methods
  public IsCurrentWordDone(): boolean {
    return this.currentTimeInMs >= this.currentWordDurationInMs;
  }

  public GetCurWord(words: Array<string>): string {
    return words[this.currentIndex];
  }

  public DecrementWord(words: Array<string>): void {
    if (this.currentIndex <= 0) {
      return;
    }

    this.currentIndex--;
    this.currentTimeInMs = 0;
    this.currentWordDurationInMs = this.CalculateWordDurationInMs(
      this.GetCurWord(words)
    );
  }

  public IncrementWord(words: Array<string>): void {
    if (this.IsDone(words)) {
      return;
    }

    this.currentIndex++;
    this.currentTimeInMs = 0;
    this.currentWordDurationInMs = this.CalculateWordDurationInMs(
      this.GetCurWord(words)
    );
  }

  public IsDone(words: Array<string>): boolean {
    return this.currentIndex + 1 >= words.length;
  }

  public Clone(): PlaybackSessionState {
    let clone: PlaybackSessionState = Object.assign(
      new PlaybackSessionState(""),
      this
    );
    clone.currentIndex = this.currentIndex;
    clone.currentTimeInMs = this.currentTimeInMs;
    clone.currentWordDurationInMs = this.currentWordDurationInMs;
    return clone;
  }

  private CalculateWordDurationInMs(word: string): number {
    let appConfig = AppConfig.Load();
    let baseWordDurationInMs = 1000 * (60 / appConfig.maxWordsPerMinute);
    return (
      baseWordDurationInMs +
      (PlaybackSessionState.CalculateSyllables(word) - 1) *
        baseWordDurationInMs *
        appConfig.syllableDurationRatio
    );
  }

  public static CalculateSyllables(word: string): number {
    const parts = word.split(/[^A-Za-z]/);
    let syllables = Math.max(
      from(parts).sum((w) => syllable.syllable(w)),
      parts.length
    );
    if (word.endsWith(".")) {
      syllables += 1;
    }
    return syllables;
  }
  // #endregion

  public static LoadOrDefault(textToRead: string): PlaybackSessionState {
    try {
      let storedState =
        SessionStorageIO.Instance.GetReferenceType(
          PlaybackSessionState,
          PlaybackSessionState.STORAGE_KEY
        ) ?? new PlaybackSessionState();
      if (storedState.textHash === StringExtensions.ComputeHash(textToRead)) {
        return storedState;
      }
      return new PlaybackSessionState(textToRead);
    } catch (error) {
      return new PlaybackSessionState(textToRead);
    }
  }

  public static SetCookie(state: PlaybackSessionState): void {
    let clone = state.Clone();
    SessionStorageIO.Instance.SetReferenceType(
      PlaybackSessionState.STORAGE_KEY,
      clone
    );
  }
}

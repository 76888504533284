import { AppConfig } from "../library/appLogic/AppConfig";
import { AppMenu, AppMenuStateArgs } from "../templateCommon/react/AppMenu";
import { AppMode } from "../library/appLogic/AppMode";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import Playback from "../library/playback/Playback";
import React from "react";

export function SpeedReaderMenu(props: {
  appMode: AppMode;
  setAppMode: React.Dispatch<React.SetStateAction<AppMode>>;
}) {
  const [appConfig, setAppConfig] = React.useState<AppConfig>(AppConfig.Load());

  function toggleIsOpenHandler(isOpen: boolean) {
    if (isOpen) {
      // pause playback if we're opening the top-level menu
      Playback.Pause();
    }
  }

  function getAppModeControl(appMenu: AppMenuStateArgs): JSX.Element {
    function ToggleIsOpen(appMenu: AppMenuStateArgs) {
      appMenu.State.ToggleIsOpen({
        handler: toggleIsOpenHandler,
        setAppMenuState: appMenu.SetState,
      });
    }

    switch (props.appMode) {
      case AppMode.Reading:
        return (
          <Button
            onClick={() => {
              props.setAppMode(AppMode.Writing);

              ToggleIsOpen(appMenu);
            }}
          >
            Edit Text
          </Button>
        );
      case AppMode.Writing:
        return (
          <Button
            onClick={() => {
              props.setAppMode(AppMode.Reading);
              ToggleIsOpen(appMenu);
            }}
          >
            Read
          </Button>
        );
      default:
        return <div style={{ textAlign: "center" }}>Whoops</div>;
    }
  }

  function generateAppMenu(appMenu: AppMenuStateArgs): JSX.Element | null {
    return (
      <>
        <div style={{ margin: 10 }}>
          <FloatingLabel label="Words Per Minute" className="mb-3">
            <Form.Control
              pattern={"[0-9]*"}
              inputMode={"numeric"}
              value={appConfig.maxWordsPerMinute}
              onChange={(e: any) => {
                e.preventDefault();
                let text = Number.parseInt(e.target.value);
                if (text <= 0) {
                  return;
                }
                AppConfig.Update((config) => {
                  config.maxWordsPerMinute = text;
                  return true;
                });
                setAppConfig(AppConfig.Load());
              }}
            />
          </FloatingLabel>
          {
            // to do: populate open menu
          }
        </div>
        <div style={{ flexGrow: 1, background: "#eee", margin: 10 }}>
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <div style={{ flexGrow: 1 }} />
            <code style={{ color: "black" }}>To Do: add controls here</code>
            <div style={{ flexGrow: 1 }} />
          </div>
        </div>
        <div style={{ textAlign: "center", margin: 10 }}>
          {getAppModeControl(appMenu)}
        </div>
      </>
    );
  }

  return (
    <AppMenu
      displayOptions={{ background: "white", svgStrokeColor: "#000" }}
      generateAppMenu={generateAppMenu}
      toggleIsOpenHandler={toggleIsOpenHandler}
    />
  );
}

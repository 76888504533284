export enum StringCase {
  Mixed,
  Upper,
  Lower,
}

export enum RandomStringType {
  Alphabetical,
  Alphanumeric,
}

export class StringExtensions {
  private static readonly Alphabet: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  private static readonly Numbers: string = "0123456789";

  public static DeserializeJson<T extends {}>(
    type: { new (): T },
    json: string | null
  ): T | null {
    try {
      return Object.assign(new type(), JSON.parse(json ?? ""));
    } catch (e) {
      return null;
    }
  }

  public static GenerateRandomString(args: {
    length: number;
    randomStringType: RandomStringType;
    stringCase: StringCase;
  }): string {
    const codeStringBuilder: Array<string> = [];
    let chars: string = StringExtensions.GetCharsForRandomSampling(args);
    while (codeStringBuilder.length < args.length) {
      codeStringBuilder.push(chars[Math.floor(Math.random() * chars.length)]);
    }

    return codeStringBuilder.join("");
  }

  private static GetCharsForRandomSampling(args: {
    randomStringType: RandomStringType;
    stringCase: StringCase;
  }): string {
    let alphabet: string;
    switch (args.stringCase) {
      case StringCase.Mixed:
        alphabet =
          StringExtensions.Alphabet.toUpperCase() +
          StringExtensions.Alphabet.toLowerCase();
        break;

      case StringCase.Upper:
        alphabet = StringExtensions.Alphabet.toUpperCase();
        break;
      case StringCase.Lower:
        alphabet = StringExtensions.Alphabet.toLowerCase();
        break;
      default:
        throw new RangeError();
    }

    switch (args.randomStringType) {
      case RandomStringType.Alphabetical:
        return alphabet;
      case RandomStringType.Alphanumeric:
        return alphabet + StringExtensions.Numbers;
      default:
        throw new RangeError();
    }
  }

  public static ComputeHash(s: string): string {
    return `${s.split("").reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0)}`;
  }
}

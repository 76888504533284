import TextInputOutputProvider from "./TextInputOutputProvider";

export default class CookieIO extends TextInputOutputProvider {
  public static readonly Instance: CookieIO = new CookieIO();

  protected SetRaw(key: string, val: string) {
    document.cookie = key + "=" + val;
  }

  protected GetRaw<T>(key: string): T | null {
    const value = "; " + document.cookie;
    const parts = value.split("; " + key + "=");

    if (parts.length === 2) {
      return (parts.pop()?.split(";")?.shift() ?? "") as unknown as T;
    }

    return null;
  }
}

import { AppMode } from "./AppMode";

export default class AppProperties {
  public readingText: string;
  public setReadingText: React.Dispatch<React.SetStateAction<string>>;

  constructor(args: {
    readingText: string;
    setReadingText: React.Dispatch<React.SetStateAction<string>>;
  }) {
    this.readingText = args.readingText;
    this.setReadingText = args.setReadingText;
  }
}

import { AppMenuState } from "../AppMenuState";
import TextInputOutputProvider from "./TextInputOutputProvider";

export default class SessionStorageIO extends TextInputOutputProvider {
  public static readonly Instance: SessionStorageIO = new SessionStorageIO();

  public static readonly APP_MENU_STATE_KEY: string = "AppMenuState";

  protected SetRaw(key: string, val: string) {
    window.sessionStorage.setItem(key, val);
  }

  protected GetRaw<T>(key: string): T | null {
    return window.sessionStorage.getItem(key) as unknown as T;
  }

  public SetAppMenuState(state: AppMenuState): void {
    this.SetReferenceType(SessionStorageIO.APP_MENU_STATE_KEY, state);
  }

  public GetAppMenuState(): AppMenuState | null {
    return this.GetReferenceType(
      AppMenuState,
      SessionStorageIO.APP_MENU_STATE_KEY
    );
  }
}

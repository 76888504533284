import { AppMenuState, AppMenuDisplayOptions } from "../ts/AppMenuState";
import { KeyEvent, Key } from "../ts/KeyboardControls";
import { motion } from "framer-motion";
import * as Events from "./Events";
import React from "react";
import SessionStorageIO from "../ts/io/SessionStorageIO";

export class AppMenuStateArgs {
  public State!: AppMenuState;
  public SetState!: React.Dispatch<React.SetStateAction<AppMenuState>>;
}

export function AppMenu(props: {
  displayOptions?: AppMenuDisplayOptions;
  // getAppModeControl: (appMenu: AppMenuStateArgs) => JSX.Element;
  generateAppMenu: (appMenu: AppMenuStateArgs) => JSX.Element | null;
  toggleIsOpenHandler?: (isOpen: boolean) => void;
}) {
  const [appMenuState, _setAppMenuState] = React.useState<AppMenuState>(
    new AppMenuState()
  );

  function setAppMenuState(state: AppMenuState) {
    SessionStorageIO.Instance.SetAppMenuState(state);
    _setAppMenuState(state);
  }

  function ToggleIsOpen(): void {
    appMenuState.ToggleIsOpen({
      handler: props.toggleIsOpenHandler ?? ((isOpen: boolean) => {}),
      setAppMenuState: setAppMenuState,
    });
  }

  Events.useEventListener("keydown", (event: KeyEvent) => {
    switch (KeyEvent.ToEnum(event)) {
      case Key.Escape:
        if (!appMenuState.isOpen) {
          return;
        }

        ToggleIsOpen();
        break;
      default:
        break;
    }
  });

  let menuButton = (
    <div style={{ textAlign: "right" }}>
      <motion.svg
        onClick={() => ToggleIsOpen()}
        {...appMenuState.GetMenuSvgProps()}
      >
        {(appMenuState.isOpen
          ? appMenuState.GetCloseMenuButtonSvgLinesProps(props.displayOptions)
          : appMenuState.GetOpenMenuButtonSvgLinesProps(props.displayOptions)
        ).map((p) => (
          <motion.line
            key={p.custom}
            {...p}
            variants={{
              hidden: { pathLength: 0, opacity: 0 },
              visible: (custom: any) => {
                const delay = custom * 0.1;
                return {
                  pathLength: 1,
                  opacity: 1,
                  transition: {
                    pathLength: {
                      delay,
                      type: "spring",
                      duration: 0.3,
                      bounce: 0,
                    },
                    opacity: { delay, duration: 0.01 },
                  },
                };
              },
            }}
          />
        ))}
      </motion.svg>
    </div>
  );

  function generateContent(appMenu: AppMenuStateArgs) {
    if (!appMenu.State.isAnimationComplete) {
      return null;
    }

    if (appMenu.State.isOpen) {
      return (
        <div
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          {menuButton}
          {props.generateAppMenu({
            State: appMenuState,
            SetState: _setAppMenuState,
          })}
        </div>
      );
    }

    return <div>{menuButton}</div>;
  }

  return (
    <div style={{ position: "fixed", right: 0 }}>
      <motion.div
        layout
        animate={appMenuState.GetAnimation(props.displayOptions)}
        style={{
          float: "right",
          zIndex: 1,
          background: props.displayOptions?.background,
        }}
        onAnimationComplete={() => {
          appMenuState.FinishAnimation(setAppMenuState);
        }}
      >
        {generateContent({
          State: appMenuState,
          SetState: _setAppMenuState,
        })}
      </motion.div>
    </div>
  );
}

import SessionStorageIO from "../../templateCommon/ts/io/SessionStorageIO";
import { PlaybackMode } from "./PlaybackMode";

export default class Playback {
  private static readonly STORAGE_KEY = "Playback";

  public static GetValue(): PlaybackMode {
    try {
      return SessionStorageIO.Instance.GetNonReferenceType<PlaybackMode>(
        Playback.STORAGE_KEY
      );
    } catch (e) {
      return PlaybackMode.Paused;
    }
  }

  public static Play(): void {
    SessionStorageIO.Instance.SetNonReferenceType(
      Playback.STORAGE_KEY,
      PlaybackMode.Playing
    );
  }

  public static Pause(): void {
    SessionStorageIO.Instance.SetNonReferenceType(
      Playback.STORAGE_KEY,
      PlaybackMode.Paused
    );
  }
}

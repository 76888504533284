import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { AppMode } from "./library/appLogic/AppMode";
import { from } from "linq-to-typescript";
import { KeyEvent, Key } from "./templateCommon/ts/KeyboardControls";
import { SpeedReaderMenu } from "./tsx/SpeedReaderMenu";
import * as Events from "./templateCommon/react/Events";
import * as React from "react";
import AppProperties from "./library/appLogic/AppProperties";
import Playback from "./library/playback/Playback";
import ReadingDisplay from "./tsx/ReadingDisplay";
import SessionStorageIO from "./templateCommon/ts/io/SessionStorageIO";
import WritingDisplay from "./tsx/WritingDisplay";

export default function App() {
  const keysDown = new Set<React.Key>();
  const [appMode, setAppMode] = React.useState<AppMode>(AppMode.Writing);
  const [readingText, setReadingText] = React.useState<string>("");
  const [windowInnerHeight, setWindowInnerHeight] = React.useState<number>(
    window.innerHeight
  );

  let appProps = new AppProperties({
    readingText,
    setReadingText,
  });

  function GetDisplay() {
    switch (appMode) {
      case AppMode.Reading:
        return <ReadingDisplay {...appProps} />;

      case AppMode.Writing:
        return <WritingDisplay {...appProps} />;

      default:
        return <div>The current app mode is: {`${appMode}`}</div>;
    }
  }

  // #region Events
  Events.useEventListener("keydown", (event: KeyEvent) => {
    let keyVal = KeyEvent.ToEnum(event);
    if (keyVal !== Key.Undefined) {
      keysDown.add(keyVal);
    }

    if (SessionStorageIO.Instance.GetAppMenuState()?.IsMenuActive()) {
      return;
    }

    switch (appMode) {
      case AppMode.Reading:
        if (keysDown.has(Key.Escape)) {
          Playback.Pause();
          setAppMode(AppMode.Writing);
        }
        break;
      case AppMode.Writing:
        if (from([Key.Enter, Key.Ctrl]).all((key) => keysDown.has(key))) {
          setAppMode(AppMode.Reading);
        }
        break;
      default:
        break;
    }
  });

  Events.useEventListener("keyup", (event: KeyEvent) =>
    keysDown.delete(KeyEvent.ToEnum(event))
  );

  Events.useEventListener("resize", (event: any) =>
    setWindowInnerHeight(window.innerHeight)
  );
  // #endregion

  return (
    <div
      style={{ height: windowInnerHeight }}
      // onKeyDown={(event) => {
      //   // console.log(event.key);
      // }}
    >
      <SpeedReaderMenu appMode={appMode} setAppMode={setAppMode} />
      {GetDisplay()}
    </div>
  );
}
